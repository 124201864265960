import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  viewBox: "0 0 512 512"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "256",
      cy: "256",
      r: "256",
      style: {"fill":"#f0f0f0"}
    }, null, -1),
    _createElementVNode("path", {
      d: "M339.147 189.217h-33.494c.277 3.681.434 7.395.434 11.13 0 24.764-6.19 48.767-16.981 65.853-3.329 5.27-8.923 12.712-16.411 17.732v-94.716h-33.391v94.716c-7.487-5.02-13.082-12.461-16.411-17.732-10.792-17.086-16.981-41.089-16.981-65.853 0-3.735.158-7.45.434-11.13h-33.494a190 190 0 0 0-.332 11.13c0 68.656 36.668 122.435 83.478 122.435s83.478-53.779 83.478-122.435c.002-3.757-.115-7.467-.329-11.13",
      style: {"fill":"#d80027"}
    }, null, -1),
    _createElementVNode("path", {
      d: "M105.739 122.435h33.391v22.261h33.391v-22.261h33.391v22.261h33.391v-22.261h33.391v22.261h33.391v-22.261h33.391v22.261h33.391v-22.261h33.391v22.261h80.337C445.19 59.065 357.493 0 256 0S66.81 59.065 25.402 144.696h80.337z",
      style: {"fill":"#6da544"}
    }, null, -1),
    _createElementVNode("path", {
      d: "M406.261 367.304v22.261H372.87v-22.261h-33.391v22.261h-33.391v-22.261h-33.391v22.261h-33.391v-22.261h-33.391v22.261h-33.391v-22.261H139.13v22.261h-33.391v-22.261H25.402C66.81 452.935 154.506 512 256 512s189.19-59.065 230.598-144.696z",
      style: {"fill":"#d80027"}
    }, null, -1)
  ])))
}
export default { render: render }